<template>
  <b-dropdown variant="outline-secondary" class="d-flex" ref="dropdown">
    <template slot="button-content">
      <i class="fa fa-user-friends"></i>
      {{allCount === 0 ? $t('search-tab.who-is-traveling') : `${allCount} ${$t('search-result.persons-selected')}`}}
    </template>
    <b-card href="#" class="border-0">
      <b-dropdown-item
        ><h5 class="text-center">
          <b-button variant="outline-primary" class="w-100">{{
            $t("search-tab.choose-count-select")
          }}</b-button>
        </h5></b-dropdown-item
      >
      <div class="form-group row align-items-center mx-2">
        <label class="col-sm-4 col-form-label">
          {{ $t("search-tab.adult") }}
          <span class="text-muted small">(12+)</span>
        </label>
        <div class="col-sm-8">
          <b-form-spinbutton
            v-model="adult"
            min="0"
            max="5"
            step="1"
            size="lg"
          ></b-form-spinbutton>
        </div>
      </div>
      <div class="form-group row align-items-center mx-2">
        <label class="col-sm-4 col-form-label">
          {{ $t("search-tab.child") }}
          <p class="text-muted small">(2~12)</p>
        </label>
        <div class="col-sm-8">
          <b-form-spinbutton
            v-model="child"
            min="0"
            max="5"
            step="1"
            size="lg"
          ></b-form-spinbutton>
        </div>
      </div>
      <div class="form-group row align-items-center mx-2">
        <label class="col-sm-4 col-form-label">
          {{ $t("search-tab.infant") }}
          <p class="text-muted small">(0~2)</p>
        </label>
        <div class="col-sm-8">
          <b-form-spinbutton
            v-model="infant"
            min="0"
            max="5"
            step="1"
            size="lg"
          ></b-form-spinbutton>
        </div>
      </div>
    </b-card>
  </b-dropdown>
</template>

<script>
import { BDropdown, BCard, BFormSpinbutton, BDropdownItem, BButton } from 'bootstrap-vue';
import TemplateOccupancyPax from './TemplateOccupancyPax';

export default {
  name: 'occupancyPaxTheme1',
  mixins: [TemplateOccupancyPax],
  components: {
    BDropdown,
    BCard,
    BFormSpinbutton,
    BDropdownItem,
    BButton,
  },
};
</script>

<style lang="scss" scoped>
  .form-group{
    margin-top : 0.5rem;
    margin-bottom : 0.5rem;

    .col-form-label{
      text-align: center;
      padding: 4px 2px;
    }
    .col-sm-8 {
      padding: 2px;
    }
  }

</style>

<style lang="scss">
.search-wrapper{
  .card-body{
    padding: 4px;
  }

  .forn-group{
    margin-top : 0.5rem;
    margin-bottom : 0.5rem;
  }

  .dropdown .btn.dropdown-toggle {
    background-color: #e9ecef;
    color: #212529;
    border-color: rgba(0, 0, 0, 0.2);
    // box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
    padding: 0.6rem;
    font-size: 1rem;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .b-form-spinbutton output{
    padding: 0;

    > div, > bdi{
      min-width: fit-content;
    }
  }
  .dropdown-item{
    &:active, &:hover {
      background-color: unset;
    }
  }
}

</style>
